export enum ModalId {
  EMPTY = "",
  ImportWishlist = "import_wishlist",
  UnsavedChanges = "unsaved_changes",
  DeleteWishlist = "delete_wishlist",
  ImportItems = "import_items",
  DeleteAccount = "delete_account",
  ClaimModal = "claim_modal",
  Logout = "logout_modal",
}
export interface IModalState {
  modalId: ModalId;
  open: boolean;
}
