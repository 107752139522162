import { defineStore } from "pinia";
import { type IModalState, ModalId } from "./entites";

export const useModal = defineStore("modal", {
  state: (): IModalState => ({
    modalId: ModalId.EMPTY,
    open: false,
  }),
  actions: {
    toggle(modalId: ModalId) {
      this.modalId = modalId;
      this.open = !this.open;
    },
    reset() {
      this.$reset();
    },
  },
});
